import { ENecsForwardStatus, ENecsForwardType } from '../types/necs-forward.types';

export const NecsForwardTypeMap = {
  [ENecsForwardType.CREATED]: { name: 'Created', tooltip: 'The Forward was created by you' },
  [ENecsForwardType.ACQUIRED]: { name: 'Acquired', tooltip: 'The Forward was ordered from a counterparty' },
  [ENecsForwardType.SCHEDULED]: { name: 'Scheduled', tooltip: 'The Forward was ordered by a counterparty' },
};

export const NecsForwardStatusMap = {
  [ENecsForwardStatus.IN_PROGRESS]: { name: 'In Progress', tooltip: 'The Forward is in the process of fulfillment' },
  [ENecsForwardStatus.NO_DELIVERY]: {
    name: 'No Delivery',
    tooltip: 'The Forward has a missed delivery, 72-hour grace period applied',
  },
  [ENecsForwardStatus.NO_PAYMENT]: {
    name: 'No Payment',
    tooltip: 'The Forward has a missed payment, 72-hour grace period applied',
  },
  [ENecsForwardStatus.NO_CONFIRMATION]: { name: 'No Confirmation', tooltip: 'The Forward has not been confirmed' },
  [ENecsForwardStatus.TERMINATED]: { name: 'Terminated', tooltip: 'The Forward was cancelled and is no longer active' },
  [ENecsForwardStatus.FULFILLED]: { name: 'Fulfilled', tooltip: 'Fulfilled' },
};
