import { ApiGetRequest } from 'types';

export enum ETransactionStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  NO_CONFIRMATION = 'no_confirmation',
  UNPAID = 'unpaid',
}

export enum ENecsTransactionSellerStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum ENecsTransactionType {
  BUY = 'buy',
  SELL = 'sell',
}

export enum ENecsTransactionModals {
  DETAILS = 'details',
  SELLER_STATUS = 'seller_status',
}

export interface FetchNecsTransactionPayload extends ApiGetRequest {
  start_date?: string;
  end_date?: string;
  transaction_type: ENecsTransactionType;
  order_txn_id?: 'asc' | 'desc';
  order_transaction_type?: 'asc' | 'desc';
  order_transaction_date?: 'asc' | 'desc';
  order_buyer_necs_company_name?: 'asc' | 'desc';
  transaction_status?: string | null;
}

export interface UpdateNecsTransactionPayload {
  id: string;
  seller_status: ENecsTransactionSellerStatus;
}

export interface INecsTransaction {
  id: string;
  transaction_id: string | null;
  stripe_payment_id: string;
  volume: number;
  price: number;
  transaction_date: string;
  counter_party_necs_go_account: string;
  counter_party_company_name: string;
  counter_party_necs_account_id: number;
  seller_bundle_id: number;
  counter_party_bundle_id: number;
  production_device_id: string;
  production_period_start: string;
  production_period_end: string;
  issued_date: string;
  date_of_expiration: string;
  transaction_type: ENecsTransactionType;
  production_device_name: string;
  transaction_status: ETransactionStatus;
  seller_status: ENecsTransactionSellerStatus;
  go_certificate_id: number;
  go_exchange_id: number;
  type_of_installation_code: string;
  type_of_installation_path: string[];
  energy_source_code: string;
  energy_source_code_path: string[];
}

export interface NecsTransactionModalPayload {
  seller_action?: ENecsTransactionSellerStatus;
}

export interface INecsTransactionModal {
  type: ENecsTransactionModals;
  payload?: NecsTransactionModalPayload;
}
