import { ENecsTransactionType, ETransactionStatus } from 'modules/necs/necs-transaction';

export const NecsTransactionTypeMap = {
  [ENecsTransactionType.BUY]: { name: 'Buy', tooltip: '' },
  [ENecsTransactionType.SELL]: { name: 'Sell', tooltip: '' },
};

export const NecsTransactionStatusMap = {
  [ETransactionStatus.CANCELLED]: { name: 'Cancelled', tooltip: '' },
  [ETransactionStatus.COMPLETED]: { name: 'Completed', tooltip: '' },
  [ETransactionStatus.PENDING]: { name: 'Pending', tooltip: '' },
  [ETransactionStatus.NO_CONFIRMATION]: { name: 'No Confirmation', tooltip: '' },
  [ETransactionStatus.UNPAID]: { name: 'Unpaid', tooltip: '' },
};
