import { ApiGetRequest } from 'types';

export enum ETransactionStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export interface FetchNecsTransactionPayload extends ApiGetRequest {
  start_date?: string;
  end_date?: string;
  transaction_type?: 'buy' | 'sell';
  order_txn_id?: 'asc' | 'desc';
  order_transaction_type?: 'asc' | 'desc';
  order_transaction_date?: 'asc' | 'desc';
  order_buyer_necs_company_name?: 'asc' | 'desc';
  transaction_status?: string | null;
}

export interface INecsTransaction {
  id: number;
  transaction_id: string | null;
  stripe_payment_id: string;
  volume: number;
  price: number;
  transaction_date: string;
  buyer_necs_go_account: string;
  buyer_company_name: string;
  seller_bundle_id: number;
  buyer_bundle_id: number;
  production_device_id: string;
  type_of_installation: string;
  energy_source: string;
  production_period_start: string;
  production_period_end: string;
  issued_date: string;
  date_of_expiration: string;
  transaction_type: 'BUY' | 'SELL';
  production_device_name: string;
  transaction_status: ETransactionStatus;
}
